import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Stack } from "@mui/material";
import axios from "axios";
import "../sidebar/sidebar.scss";
import "react-toastify/dist/ReactToastify.css";
import ProfileModal from "../modals/profile modal/profileModal";
import ReportModal from "../modals/ReportModal/ReportModal";
import { BaseUrl, ImageBaseUrl } from "../../Environment";

import customerloan from "../../assets/images/customerloan.svg";
import dashboard from "../../assets/images/dashboard.svg";
import details from "../../assets/images/details.svg";
import upgrade from "../../assets/images/upgrade.svg";
import help from "../../assets/images/help.svg";
import loan from "../../assets/images/loan.svg";
import members from "../../assets/images/members.svg";
import expense from "../../assets/images/expense.svg";
import assets from "../../assets/images/assets.svg";
import userdefault from "../../assets/images/userdefault.svg";
import reportsicon from "../../assets/images/reportsicon.svg";
import printDoc from "../../assets/images/printDoc.svg";
import settings from "../../assets/images/settings.svg";

const Sidebar = () => {
  const userPermissions = JSON.parse(localStorage.getItem("UsersAccess"));
  const loggedinUser = JSON.parse(localStorage.getItem("loggedinUser"));

  const [openModal, setOpenModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [openLoanSubMenu, setOpenLoanSubMenu] = useState(false);
  const [activeStyle, setActiveStyle] = useState({});
  const [loggedinUserDetails, setLoggedinUserDetails] = useState(null);
  const [profileCard, setProfileCard] = useState("2px solid lightgrey");
  const location = useLocation();
  const history = useHistory();

  const toggleModal = (setter) => () => setter((prev) => !prev);

  const getData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      };
      const details = JSON.parse(localStorage.getItem("loggedinUser"));
      const response = await axios.get(
        `${BaseUrl}/user/getUserDetails?userId=${details.userId}`,
        { headers }
      );

      if (response.status === 200) {
        setLoggedinUserDetails(response.data);
      } else {
        toast.error("Please try again later!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getData();
      const path = location.pathname;

      const newActiveStyle = {
        dashboard: path === "/dashboard",
        allnewcustomers: [
          "/allnewcustomers",
          "/customers/addcustomer",
        ].includes(path),
        customer: [
          "/customer",
          "/addloannointerest",
          "/loandisbursement",
        ].includes(path),
        loantype: ["/loantype", "/loan/editloan", "/addloan"].includes(path),
        collectionreport: path === "/collectionreport",
        share: path === "/share",
        help: path === "/help",
        profile: [
          "/usermanagement",
          "/companyprofile",
          "/updateadmin",
          "/updatepassword",
        ].some((route) => path.startsWith(route)),
        upgrade: path === "/pricing",
        expense: path === "/expenses",
        otherIncome: path === "/otherincome",
        printDocs: path === "/printdocs",
        assets: ["/asset", "/assettypes"].includes(path),
        settings: path === "settings"
      };

      setActiveStyle(newActiveStyle);
      setProfileCard(
        newActiveStyle.profile ? "2px solid #00D95E" : "2px solid lightgrey"
      );
    } else {
      history.push("/login");
    }
  }, [location, history]);

  return (
    <div className="sidepage">
      <div className="area"></div>
      <nav className="main-menu">
        <ul>
          <MenuItem
            isActive={activeStyle.dashboard}
            path="/dashboard"
            icon={dashboard}
            tooltip="Dashboard"
          />
          {userPermissions?.viewBorrowers && <MenuItem
            isActive={activeStyle.allnewcustomers}
            path="/allnewcustomers"
            icon={members}
            tooltip="Borrowers"
          />}
          {userPermissions?.viewLoan || userPermissions?.approveLoan ? <MenuItem
            isActive={activeStyle.customer}
            onClick={() => setOpenLoanSubMenu(!openLoanSubMenu)}
            icon={customerloan}
            tooltip="Loans"
          /> : <></>}
          {openLoanSubMenu && (
            <div style={{ backgroundColor: "gray" }}>
              {userPermissions?.viewLoan && <MenuItem
                isActive={activeStyle.customer}
                path="/customer"
                icon={customerloan}
                tooltip="All Loans"
                fontSize={10}
              />}
              <MenuItem
                isActive={activeStyle.customer}
                path="/loandisbursement"
                icon={members}
                tooltip="Approved/Disburse"
                fontSize={10}
              />
            </div>
          )}
          <MenuItem
            isActive={activeStyle.loantype}
            path="/loantype"
            icon={loan}
            tooltip="Loan Type"
          />
          {userPermissions?.viewReports && <MenuItem
            onClick={toggleModal(setOpenReportModal)}
            icon={reportsicon}
            tooltip="Reports"
          />}
          {userPermissions?.Expenses && <MenuItem
            isActive={activeStyle.expense}
            path="/expenses"
            icon={expense}
            tooltip="Expenses"
          />}
          {userPermissions?.accounting && <MenuItem
            isActive={activeStyle.otherIncome}
            path="/otherincome"
            icon={details}
            tooltip="Other Income"
          />}
          {userPermissions?.accounting && <MenuItem
            isActive={activeStyle.assets}
            path="/asset"
            icon={assets}
            tooltip="Asset Management"
          />}
          {userPermissions?.printDocs && <MenuItem
            isActive={activeStyle.printDocs}
            path="/printdocs"
            icon={printDoc}
            tooltip="Print Documents"
          />}
        </ul>
        <ul className="logout">          
          <MenuItem
            onClick={toggleModal(setOpenModal)}
            icon={
              loggedinUserDetails?.userImagePath
                ? `${ImageBaseUrl}${loggedinUserDetails.userImagePath}`
                : userdefault
            }
            tooltip="Profile"
          />          
          {loggedinUser?.userType === "superAdmin" && <MenuItem
            isActive={activeStyle.settings}
            path="/settings"
            icon={settings}
            tooltip="Settings"
          />}
          <MenuItem
            isActive={activeStyle.help}
            path="/help"
            icon={help}
            tooltip="Help"
          />
          <MenuItem
            isActive={activeStyle.upgrade}
            path="/pricing"
            icon={upgrade}
            tooltip="Upgrade"
          />
        </ul>
        <ProfileModal open={openModal} close={toggleModal(setOpenModal)} />
        <ReportModal
          open={openReportModal}
          close={toggleModal(setOpenReportModal)}
        />
      </nav>
    </div>
  );
};

const MenuItem = ({
  isActive = false,
  path,
  icon,
  tooltip,
  onClick,
  fontSize,
}) => (
  <div
    className="borderLeft"
    style={{ borderLeft: isActive ? "3px solid #00D95E" : "" }}
  >
    <li
      className="liClass"
      style={{ backgroundColor: isActive ? "#1fab98" : "" }}
      onClick={onClick}
    >
      {path ? (
        <Link to={path}>
          <MenuItemContent icon={icon} tooltip={tooltip} fontSize={fontSize} />
        </Link>
      ) : (
        <MenuItemContent icon={icon} tooltip={tooltip} fontSize={fontSize} />
      )}
    </li>
  </div>
);

const MenuItemContent = ({ icon, tooltip, fontSize }) => (
  <Stack spacing={fontSize ? 1.5 : 3} direction={"row"} alignItems={"center"}>
    <img
      className="fa fa-2x"
      style={{ width: fontSize && "15px", height: fontSize && "15px" }}
      src={icon}
      alt={tooltip.toLowerCase()}
    />
    <h6 style={{ color: "white", fontSize: fontSize ? fontSize : 13 }}>
      {tooltip}
    </h6>
  </Stack>
);

export default Sidebar;
